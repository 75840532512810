module.exports = {
    "metadata": {
        "name": "Gbook",
        "short_name": "Gbook",
        "description": "Gbook - awesome GitBook-like documentation for Risk and Acturary",
        "language": "en",
        "url": "http://localhost",
        "pathPrefix": "/",
        "gaTrackingId": null,
        "siteImage": null,
        "favicon": "/assets/GofFavicon32.png",
        "themeColor": "#37474F"
    },
    "header": {
        "logo": "/assets/GoFwobg96.png",
        "logoLink": "/",
        "helpUrl": "",
        "links": [
            {
                "text": "Gbook",
                "link": "https://www.gactuary.com",
                "external": false
            },
            {
                "text": "IFRS",
                "link": "https://www.gactuary.com/ifrs",
                "external": true
            },
            {
                "text": "References",
                "link": "/",
                "external": false,
                "submenus": [
                    {
                        "text": "Kics",
                        "link": "https://www.gactuary.com/kics"
                    },
                    {
                        "text": "IFRS17",
                        "link": "https://www.gactuary.com/ifrs"
                    },
                    {
                        "text": "GESG",
                        "link": "https://www.gactuary.com/gesg"
                    }
                ]
            },
            {
                "text": "Solutions",
                "link": "/",
                "external": false,
                "submenus": [
                    {
                        "text": "GMV",
                        "link": "https://www.gactuary.com/ifrs"
                    },
                    {
                        "text": "GESG",
                        "link": "https://www.gactuary.com/gesg"
                    }
                ]
            }
        ],
        "enabled": true,
        "logolink": "https://www.gactuary.com"
    },
    "sidebar": {
        "enabled": true,
        "ignoreIndex": false,
        "forcedNavOrder": [],
        "expanded": [],
        "groups": [
            {
                "order": 1,
                "path": "/Notice",
                "title": ":rocket: Notice"
            },
            {
                "order": 2,
                "path": "/Blog",
                "title": ":wrench: Blog"
            },
            {
                "order": 3,
                "path": "/03-ac",
                "title": ":writing_hand: III.지급여력금액"
            },
            {
                "order": 4,
                "path": "/04-scr",
                "title": ":rocket: IV.지급여력기준금액"
            },
            {
                "order": 5,
                "path": "/06-trans",
                "title": ":computer: VI.경과규정"
            },
            {
                "order": 5,
                "path": "/05-doc",
                "title": ":computer: V.문서화"
            }
        ],
        "links": [
            {
                "text": "GoF",
                "link": "https://github.com/filipowm/boogi"
            },
            {
                "text": "React",
                "link": "https://reactjs.org"
            }
        ],
        "poweredBy": {
            "trademark": null,
            "name": null,
            "link": null
        }
    },
    "pwa": {
        "manifest": {
            "name": "BooGi",
            "short_name": "BooGi",
            "start_url": "/",
            "background_color": "#6b37bf",
            "theme_color": "#6b37bf",
            "display": "standalone",
            "crossOrigin": "anonymous",
            "icon": "static/assets/GoF144.png",
            "description": "Gbook - awesome GitBook-like documentation for Risk and Acturary",
            "cache_busting_mode": "none",
            "include_favicon": false,
            "lang": "en"
        },
        "enabled": true
    },
    "social": {
        "facebook": "",
        "github": null,
        "gitlab": "",
        "instagram": "",
        "linkedin": null,
        "mail": "",
        "gmail": "",
        "slack": "",
        "twich": "",
        "twitter": null,
        "youtube": ""
    },
    "features": {
        "editOnRepo": {
            "editable": false,
            "location": "https://github.com/filipowm/boogi",
            "type": "github"
        },
        "search": {
            "enabled": false,
            "indexName": "docs",
            "algoliaAppId": null,
            "algoliaSearchKey": null,
            "algoliaAdminKey": null,
            "excerptSize": 8000,
            "engine": "algolia",
            "placeholder": "Search",
            "startComponent": "input",
            "debounceTime": 380,
            "snippetLength": 23,
            "hitsPerPage": 10,
            "showStats": true,
            "localSearchEngine": {
                "encode": "advanced",
                "tokenize": "full",
                "threshold": 2,
                "resolution": 30,
                "depth": 20
            },
            "pagination": {
                "enabled": true,
                "totalPages": 10,
                "showNext": true,
                "showPrevious": true
            }
        },
        "toc": {
            "show": true,
            "depth": 4
        },
        "previousNext": {
            "enabled": true,
            "arrowKeyNavigation": true
        },
        "scrollTop": true,
        "showMetadata": true,
        "propagateNetlifyEnv": true,
        "pageProgress": {
            "enabled": true,
            "excludePaths": [
                "/"
            ],
            "height": 3,
            "prependToBody": false,
            "color": "#A05EB5",
            "includePaths": [
                "/configuration/settingup/features"
            ]
        },
        "mermaid": {
            "language": "mermaid",
            "theme": "dark",
            "options": {},
            "width": 400,
            "height": 300
        },
        "rss": {
            "enabled": false,
            "showIcon": false,
            "title": "BooGi Docs",
            "copyright": "2020, Mateusz Filipowicz",
            "webMaster": "Mateusz Filipowicz",
            "managingEditor": "Mateusz Filipowicz",
            "categories": [
                "Docs as Code",
                "GatsbyJS"
            ],
            "ttl": 60,
            "matchRegex": "^/",
            "outputPath": "/rss.xml",
            "generator": "boogi"
        },
        "darkMode": {
            "enabled": true,
            "default": false
        },
        "publishDraft": false,
        "fullScreenMode": {
            "enabled": true,
            "hideHeader": true,
            "hideToc": true,
            "hideSidebar": true
        }
    }
};